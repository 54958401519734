import * as React from "react";
import { useState } from "react";
import { Header, Container, Segment, Icon, Form, FormProps, Message } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const listingPlaceholder = (
`## Mission and Summary

## Role and Responsibilities

## Experience and Requirements

## Compensation

## Benefits
`);

const SubmitJobPage = () => {
  const [formData, setFormData] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event: (React.ChangeEvent | React.FormEvent)) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value.trim(),
    });
  };

  const handleSubmitForm = (ev: React.FormEvent) => {
    setSubmitted(true);
    ev.preventDefault();
    const submitData = new URLSearchParams();
    const values = formData as any;
    Object.keys(values).forEach((key) => submitData.append(key, values[key]));
    const result = fetch(
      "https://formspree.io/f/xbjpyzja",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: submitData,
      },
    );
    result.then((result) => {
      console.log(result);
      setSuccess(true);
    });
  };

  return (
    <Container text>
      <Segment vertical>
        <Header as="h2">
          <Icon name="angle double up" />
          <Header.Content>
            Submit a Job
          </Header.Content>
        </Header>
        <p>
          If you're building the next economy and you're hiring tech workers, your organization belongs here.
          Please submit your role and we'll be in touch about listing it for free.
        </p>
        <Form
          onSubmit={handleSubmitForm}
          success={success}
        >
          <Form.Input fluid onChange={handleChange} name="email" label="Contact Email" placeholder="jobs@amazing.coop"></Form.Input>
          <Form.Input fluid onChange={handleChange} name="organization" label="Organization Name" placeholder="Tech Workers Cooperative"></Form.Input>
          <Form.Input fluid onChange={handleChange} name="role" label="Role" placeholder="Product Manager"></Form.Input>
          <Form.Input fluid onChange={handleChange} name="location" label="Location" placeholder="New York / Remote"></Form.Input>
          <Form.Input fluid onChange={handleChange} name="url" label="Website To Apply" placeholder="https://amazing.coop/jobs"></Form.Input>
          <Form.TextArea onChange={handleChange} name="details" label="Details" placeholder={listingPlaceholder} style={{minHeight: 200}} />
          <Form.Button disabled={submitted}>Submit</Form.Button>
          <Message
            success
            header="Role Submitted"
            content="We'll reach out by email"
          />
        </Form>
      </Segment>
    </Container>
  );
};

export default withLayout(SubmitJobPage);
